import React from "react"
import Layout from "../components/layout"
import bg from "../images/backgrounds/detail-bg.png"
import Row from "../components/row"
import text from "../data/nl"
import { graphql, Link } from "gatsby"

import SEO from "../components/seo"

const detailPage = (data) => (

  <Layout>
    <SEO title={text.aboutUs.meta.title} description={text.aboutUs.meta.description}/>
    <Row size="small" image={bg} classes="pt-200" id="AboutDdc">
      <div id="AboutDdcIntro">
        <div className="hbox">
          <h1 className="uppercase t-50 bold mb-40 column col-12">{text.aboutUs.intro.title}</h1>
          <div className="align-justify column col-6" dangerouslySetInnerHTML={{ __html: text.aboutUs.intro.descriptionFirstPart }}/>
          <div className="column col-6">
            <img src={text.aboutUs.intro.image} alt={text.aboutUs.intro.title} className="mt-9 max-width-100 max-height-100"/>
          </div>
        </div>
        <h3 className="column col-12 uppercase t-30 bold mb-20">{text.aboutUs.intro.descriptionSecondPartTitle}</h3>
        <div className="column col-12 mb-80 align-justify text-col-2 text-col-gap-big" dangerouslySetInnerHTML={{ __html: text.aboutUs.intro.descriptionSecondPart }}/>
      </div>
      <div className="column col-12" id="HomePartners">
        <h2 className="uppercase t-30 bold mb-20">{text.aboutUs.partners.title}</h2>
        <ul className="hbox cross-center list-style-none space-between mb-40 partner-list">
          {text.home.partners.partners.map((partner, index) =>
            <li className={"mt-40 h-100 partner-list-item-" + index} key={partner.alt}>
              <Link to={partner.ref} className="block full-height relative">
                <img className="max-width-100 max-height-100 center-vertically" src={partner.image}
                     alt={partner.alt}
                     style={{ height: partner.imageHeight != null ? partner.imageHeight : "auto" }}/>
              </Link>
            </li>
          )}
        </ul>
      </div>
      <div className="col-12 column">
        <h2 className="uppercase t-30 bold mb-20">{text.aboutUs.team.title}</h2>
        <p>{text.aboutUs.team.description}</p>
      </div>
      <ul className="list-style-none hbox mt-60 mb-60" id="AboutDdcEmployees">
        {data.data.allEmployees.nodes.map((employee) =>
            <li className="col-3 column mb-40 relative" key={employee.name}>
              <div className="relative employee-details-hover-trigger">
                <img src={employee.image} alt={employee.name} className="block full-width"/>
                <div className="employee-details">
                  <div className="mt-70">
                    {employee.skills.map((skill, i) =>
                      <span key={employee.name + skill}>
                        {skill + ( (employee.skills.length -1) !== i ? ", " : "")}
                      </span>
                    )}
                  </div>
                  <a href={employee.linkedIn} target="_blank" rel="noopener noreferrer nofollow" className={employee.linkedIn != null && employee.linkedIn !== "" ? "" : "hidden"} title={"LinkedIn profiel van " + employee.name }>
                    <svg className="linkedin-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M416 32H31.9C14.3 32 0 46.5 0 64.3v383.4C0 465.5 14.3 480 31.9 480H416c17.6 0 32-14.5 32-32.3V64.3c0-17.8-14.4-32.3-32-32.3zM135.4 416H69V202.2h66.5V416zm-33.2-243c-21.3 0-38.5-17.3-38.5-38.5S80.9 96 102.2 96c21.2 0 38.5 17.3 38.5 38.5 0 21.3-17.2 38.5-38.5 38.5zm282.1 243h-66.4V312c0-24.8-.5-56.7-34.5-56.7-34.6 0-39.9 27-39.9 54.9V416h-66.4V202.2h63.7v29.2h.9c8.9-16.8 30.6-34.5 62.9-34.5 67.2 0 79.7 44.3 79.7 101.9V416z"/></svg>
                  </a>
                </div>
              </div>
              <div className="float-left full-width pt-20">
                <div className="uppercase bold">
                  {employee.name}
                </div>
              </div>
            </li>
        )}
        <li className="col-3 column mb-40 relative" key="jij">
          <img src={text.aboutUs.team.you.image} alt={text.aboutUs.team.you.alt} className="float-left block full-width"/>
          <div className="float-left full-width pt-20">
            <div className="uppercase bold">
              <Link to={text.aboutUs.team.you.ref} title={text.aboutUs.team.you.alt} className="overlay-link"/>
              <Link to={text.aboutUs.team.you.ref} title={text.aboutUs.team.you.alt}>{text.aboutUs.team.you.name}</Link>
            </div>
          </div>
        </li>
      </ul>
    </Row>
  </Layout>
)

export default detailPage;

export const query = graphql`
query {
  allEmployees(sort: { fields: [order], order: ASC } ) {
    nodes {
      name,
        title,
        linkedIn,
        image,
        skills
        order
    }
  }
}
`
